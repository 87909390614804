import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { StripeAPI } from 'src/app/api/stripe-service.api';
import { VendorQuery } from '../vendor/vendor.query';
import { SubscriptionsStore } from './subscriptions.store';
import { StripeSubscription, Subscription, Tier } from 'sustainment-component';
import { VendorActions } from '../vendor/vendor.action';

@Injectable({ providedIn: 'root' })
export class SubscriptionsActions {
  public constructor(
    private _store: SubscriptionsStore,
    private stripeApi: StripeAPI,
    private vendorQuery: VendorQuery,
    private _vendorActions: VendorActions
  ) {}

  public getPlans(): void {
    this._store.setLoading(true);

    this.stripeApi
      .getPlans()
      .pipe(
        tap((result) => {
          this._store.update({ plans: result });
        })
      )
      .subscribe(
        () => {
          this._vendorActions.sustainmentId$.subscribe((sustainmentId) => {
            if (sustainmentId) {
              this.getCustomerSubscriptions();
            } else {
              this._store.setLoading(false);
            }
          });
        },
        (error) => {
          if (!error.ok) {
            this._store.update({
              errorMessage: 'Stripe service is offline. Try again later.'
            });
            this._store.setLoading(false);
          }
        }
      );
  }

  public getCustomerSubscriptions(): void {
    const vendorSustainmentId =
      this.vendorQuery.getValue().vendor?.sustainmentId;
    if (!vendorSustainmentId)
      throw 'Cannot get subscription for no sustainmentId';
    this.stripeApi.getSubscriptions(vendorSustainmentId).subscribe(
      (result) => {
        if (result) {
          // no customer or subscriptions
          if (!result?.subscriptions?.length) {
            this._store.update({
              stripeCustomer: result || undefined,
              activeProduct: undefined,
              isStripeSet: !!result,
              canTrial: !result.canceledSubscriptions.some((h) => h.trialEnd)
            });

            this._store.setLoading(false);
            return;
          }

          const activeProd = result.subscriptions[0];
          const activePlanId = activeProd.items[0].price.id;
          this._store.update((value) => ({
            stripeCustomer: {
              ...result,
              subscriptions: result.subscriptions?.map((x) =>
                this.formatSubscription(x, value.plans[0])
              )
            },
            plans: value.plans.map((subs) => ({
              product: subs.product,
              plans: subs.plans.map((plan) => {
                return {
                  ...plan,
                  // eslint-disable-next-line
                  user_have_plan: activePlanId === plan.id,
                  tiers: plan.tiersMode
                    ? this.calculateTieredPrices(plan.tiers, activeProd)
                    : []
                };
              })
            })),
            activeProduct: this.formatSubscription(
              activeProd,
              value.plans.find((x) =>
                x.plans.find((y) => y.id === activePlanId)
              )!
            ),
            isStripeSet: true,
            canTrial: !result.canceledSubscriptions.some((h) => h.trialEnd)
          }));
        }

        this._store.setLoading(false);
      },
      () => this._store.setLoading(false)
    );
  }

  private formatSubscription(
    sub: Subscription,
    plan: StripeSubscription
  ): Subscription {
    return {
      ...sub,
      items: sub.items.map((item) => {
        return {
          ...item,
          name: plan.product.name,
          metadata: plan.product.metadata
        };
      })
    };
  }

  private calculateTieredPrices(
    planTiers: Tier[],
    activeProd: Subscription
  ): Tier[] {
    const tiers: Tier[] = planTiers.map((x) => ({ ...x }));

    const extraUsers = planTiers.length
      ? activeProd.items[0].quantity - (planTiers[0].upTo || 0)
      : 0;

    if (extraUsers > 0) {
      tiers[0].flatAmount ??= 0;
      tiers[0].flatAmount += (tiers[1].unitAmount || 0) * extraUsers;
    }

    return tiers;
  }
}
