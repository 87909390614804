import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SubscriptionsStore } from './subscriptions.store';
import { IStripeStore } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
export class SubscriptionsQuery extends Query<IStripeStore> {
  public activeSubscriptions$ = this.select(
    (x) => x.stripeCustomer?.subscriptions || []
  );
  public activeProduct$ = this.select((x) => x.plans || []);
  public constructor(protected store: SubscriptionsStore) {
    super(store);
  }
}
