import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseApi, OrganizationEmail } from 'sustainment-component';

@Injectable()
export class PlatformCommuncationApi extends BaseApi {
  protected urlBase: string;
  public constructor(public _http: HttpClient) {
    super(_http);
    this.urlBase = environment.platFormCommuncationsUrl;
  }

  public getOrganizationEmail(
    sustainmentId: string
  ): Observable<OrganizationEmail> {
    return this.get<OrganizationEmail>(`/OrganizationEmail/${sustainmentId}`);
  }

  public getNotificationSettings(): Observable<NotificationSettingsModel> {
    return this.get<NotificationSettingsModel>('/settings/notifications');
  }

  public updateNotificationSettings(
    emailNotifications: NotificationSettingsModel
  ): Observable<void> {
    return this.put<void>('/settings/notifications', { emailNotifications });
  }
}

type DropdownNotificationCategory = 'teamMessages';
type ToggleNotificationCategory =
  | 'requests'
  | 'opportunities'
  | 'projects'
  | 'companyChats';

export type NotificationCategory =
  | DropdownNotificationCategory
  | ToggleNotificationCategory;

export type NotificationSettingsModel = {
  [key in
    | ToggleNotificationCategory
    | DropdownNotificationCategory]: key extends ToggleNotificationCategory
    ? boolean
    : number;
};
